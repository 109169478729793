<template>
  <!-- <div id="ProjectBar" @scroll="scrollEvent" ref="ProjectBar"> -->
  <div id="ProjectBar" ref="ProjectBar">
    <!-- <div
      v-if="this.user_info.permission != 0"
      :class="['team-cascader', teamShow ? '' : 'disable-team']"
      @click="showDrawer"
    >
      <img src="@/assets/img/Icon/side_pull.png" alt="" />
    </div> -->
    <!--用于设置跳转锚点-->
    <div id="topAnchor"></div>
    <!--返回-->
    <div class="getBack" @click="getBackDir" v-if="PageState == 1">
      <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
      <span>返回</span>
    </div>
    <div class="newBox">
      <button class="task" @click="newTaskFn" v-show="dirID != -1">
        <span>新增任务</span>
      </button>
      <button class="folder" @click="addFolderBox = true" v-show="dirID == -1">
        <span>新增预案</span>
      </button>
    </div>

    <!--搜索项目-->
    <div class="searchProject">
      <i class="ivu-icon ivu-icon-ios-search"></i>
      <input
        type="text"
        v-model="searchMsg"
        placeholder="搜索任务或文件夹"
        @keyup.enter="searchProjectFn"
      />
    </div>
    <!--项目显示-->
    <div class="ProjectList">
      <h1>最新 <i class="ivu-icon ivu-icon-ios-arrow-round-down"></i></h1>
      <div v-if="noData" class="noData">
        <h1>暂无数据</h1>
      </div>
      <!--项目-->
      <Spin size="large" style="margin-top: 30px" fix v-if="loading"></Spin>
      <div class="ListBox1" v-if="!noData">
        <div
          class="ListItem"
          v-for="(item, ind) in listData"
          :key="item.ind"
          :style="`z-index: ${300 - ind}`"
        >
          <div class="ItemLeft" @click="IntoDir(item.team_id, item.id)">
            <img src="../../assets/img/Marker/icon_project_folder.png" alt="" />
          </div>
          <div class="ItemContent" @click="IntoDir(item.team_id, item.id)">
            <h2 :title="item.dir_name">{{ item.dir_name }}</h2>
            <h3>{{ item.data_create_time }}</h3>
          </div>
          <div class="ItemRight" v-if="TeamID == user_info.team_id">
            <Poptip placement="bottom-end">
              <i class="more_i ivu-icon ivu-icon-md-more"></i>
              <div class="api" slot="content">
                <div class="Button" @click="editShou(item)">
                  <Icon type="md-create"></Icon>
                  重命名
                </div>
                <div class="Divider"></div>
                <div class="Button" @click="delete_Level_2(item)">
                  <Icon type="md-trash"></Icon>
                  删除
                </div>
              </div>
            </Poptip>
          </div>
        </div>
        <div
          class="ListItem"
          v-for="(item, ind) in taskData"
          :key="item.ind"
          :style="`z-index: ${200 - ind}`"
        >
          <div class="ItemLeft" @click="IntoheTask(item)">
            <img
              src="../../assets/img/Marker/line_green.png"
              alt=""
              v-if="item.tasktype == 'airroute'"
            />
            <img
              src="../../assets/img/Marker/pic_red.png"
              alt=""
              v-if="item.tasktype == 'figure'"
            />
          </div>
          <div class="ItemContent" @click="IntoheTask(item)">
            <h2 :title="item.filetitle">{{ item.filetitle }}</h2>
            <h3 title="创建时间">{{ item.data_create_time }}</h3>
            <h3 title="更新时间">{{ item.data_update_time }}</h3>
          </div>
          <div class="ItemRight" v-if="TeamID == user_info.team_id">
            <!--<div class="ItemRight" v-if="false">-->
            <Poptip placement="bottom-end">
              <i class="more_i ivu-icon ivu-icon-md-more"></i>
              <div class="api" slot="content">
                <!--<div class="Button" @click="editShou_Task(item)">-->
                <!--<Icon type="md-create"></Icon>-->
                <!--编辑-->
                <!--</div>-->
                <!--<div class="Divider"></div>-->
                <div class="Button" @click.stop="delete_Task(item)">
                  <Icon type="md-trash"></Icon>
                  删除
                </div>
              </div>
            </Poptip>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="goTop" @click="scrollToSection" ref="goTop">回到顶部</div> -->
    <!--弹窗区-->
    <!--编辑文件夹信息-->
    <Modal
      v-model="editBox"
      width="360"
      class-name="editProject-center-modal"
      @on-cancel="closeEditProject"
    >
      <p slot="header" style="color: #409eff; text-align: center">
        <span>编辑项目名称</span>
      </p>
      <div style="padding: 10px 30px">
        <p>修改项目名称</p>
        <Input v-model="editDir_name" show-word-limit maxlength="15"></Input>
      </div>
      <div style="text-align: center" slot="footer">
        <Button
          type="info"
          @click="editProject"
          style="width: 200px; letter-spacing: 8px"
          >确认</Button
        >
      </div>
    </Modal>
    <!--添加文件夹-->
    <Modal
      v-model="addFolderBox"
      width="360"
      class-name="addFolder-center-modal"
      @on-cancel="closeAddFolder"
    >
      <p slot="header" style="color: #409eff; text-align: center">
        <span>新建项目</span>
      </p>
      <div style="padding: 10px 30px">
        <p>项目名称</p>
        <Input v-model="addFolder_name" show-word-limit maxlength="15"></Input>
      </div>
      <div style="text-align: center" slot="footer">
        <Button
          type="info"
          @click="addFolderFn"
          style="width: 200px; letter-spacing: 8px"
          >确认</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from 'lodash'
import Api from '@/utils/api.js'
export default {
  name: 'ProjectBar',
  props: {
    ShowMarker: {
      type: Function,
      default: null,
    },
    shouAirRoute: {
      type: Function,
      default: null,
    },
  },
  data: function () {
    return {
      user_info: {},
      userType: 1,
      newProjectShou: true,
      searchMsg: '',
      teamPitchOn: [],
      teamData: [],
      listStatus: true,
      listData: [], //文件夹列表数据
      taskData: [], //任务列表数据
      ProjectType: 0, //1->一级工程,2->二级工程,3->航线建图任务
      Level_1_ID: null,
      Level_2_ID: null,
      noData: false,
      editBox: false, //编辑弹框
      editDir_name: '',
      editProjectOriginalDataID: '',
      placeholder: '搜索您的项目',
      selectedTeamData: '',
      PageState: 0, //0.文件夹和任务列表 //1.进入文件夹里的任务列表
      TeamID: -1,
      addFolderBox: false, //添加文件夹
      addFolder_name: '',
      dirID: -1,
      loading: true, // 加载效果
    }
  },
  mounted() {
    this.user_info = JSON.parse(sessionStorage.getItem('user_info'))
    this.userType = sessionStorage.getItem('userType')
    let dirInfo = JSON.parse(sessionStorage.getItem('dirInfo'))
    if (
      (sessionStorage.getItem('team_id') === null || this,
      this.user_info.permission == 0)
    ) {
      this.TeamID = this.user_info.team_id
      if (dirInfo) {
        this.IntoDir(dirInfo.team, dirInfo.id)
      } else {
        this.getAirRouteList(this.TeamID)
      }
    } else {
      this.TeamID = Number(sessionStorage.getItem('team_id'))
      this.selectedTeamData = Number(sessionStorage.getItem('team_id'))
      if (dirInfo) {
        this.IntoDir(dirInfo.team, dirInfo.id)
      } else {
        this.getAirRouteList(this.TeamID)
      }
    }
    // this.TeamID = this.user_info.team_id;

    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.selectedTeamData = value
      this.getBackDir()
    })
    // if(this.userType==0 && !this.$route.query.id){
    //     this.$router.push({
    //         path: "/index/project/ProjectBar_Level_1"
    //     });
    // }else if(this.userType==0 && this.$route.query.id){
    //     this.Level_2_projectData(this.$route.query.id);
    //     // 改变显示项目
    //     this.$_bus.$off("Into_2_project");
    //     this.$_bus.$on("Into_2_project",function(item){
    //         _this.IntoheProject(item);
    //     });
    // }else {
    //     this.Level_2_projectData(this.user_info.pm_id);
    //     // 改变显示项目
    //     this.$_bus.$off("Into_2_project");
    //     this.$_bus.$on("Into_2_project",function(item){
    //         _this.IntoheProject(item);
    //     });
    // }
  },
  methods: {
    // 搜索项目
    searchProjectFn() {
      if (this.dirID == -1) {
        this.getAirRouteList(this.selectedTeamData, this.searchMsg)
      } else {
        this.IntoDir(this.TeamID, this.dirID)
      }
    },
    // 加载团队数据
    getTeamData(id) {
      this.$post(Api.teamList(), {
        team_id: id,
      }).then((res) => {
        if (res.code == 1) {
          this.teamData = []
          res.data.teamlist.forEach((item) => {
            this.teamData.push({
              name: item.team_name,
              color: item.comment,
              id: item.id,
            })
          })
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    // 进入文件夹查看任务列表
    // IntoheProject(item) {
    //   sessionStorage.setItem('Project2info', JSON.stringify(item));
    //   this.$router.push({
    //     path: '/index/project/ProjectBar_task',
    //     query: {
    //       id: item.pm_id,
    //     },
    //   });
    // },
    // 监听滚动距离
    // scrollEvent() {
    //   var top = this.$refs.ProjectBar.scrollTop;
    //   if (top >= 650) {
    //     this.$refs.goTop.style.display = "block";
    //   } else {
    //     this.$refs.goTop.style.display = "none";
    //   }
    // },
    // 回到顶部
    // scrollToSection() {
    //   document
    //     .getElementById("topAnchor")
    //     .scrollIntoView({ behavior: "smooth" });
    // },
    // 编辑弹框显示
    // 编辑项目
    editShou(item) {
      this.editBox = true
      this.editProjectOriginalDataID = item.id
      this.editDir_name = item.dir_name
    },
    // 提交编辑项目
    editProject() {
      if (this.editDir_name == '') {
        this.$Message.warning('项目名称不能为空')
        return false
      }
      this.$post(Api.editTaskDir(), {
        client: 'web',
        dir_name: this.editDir_name,
        dir_id: this.editProjectOriginalDataID,
      }).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.$Message.success(res.msg_customer)
          this.getAirRouteList(this.TeamID)
          this.editBox = false
          this.closeEditProject()
        } else {
          this.$Notice.error({
            title: '提示',
            desc: res.msg_customer,
          })
        }
      })
    },
    // 关闭了编辑弹窗
    closeEditProject() {
      this.editProjectOriginalDataID = ''
      this.editDir_name = ''
    },

    //删除文件夹
    delete_Level_2(item) {
      console.log(item)
      this.$Modal.confirm({
        title: '删除项目',
        content: `是否删除  ${item.dir_name}  此项目,删除后数据无法恢复,谨慎删除!`,
        onOk: () => {
          this.$post(Api.deleteTaskDir(), {
            dir_id: item.id,
            client: 'web',
          }).then((res) => {
            console.log('删除项目', res)
            if (res.code == 1) {
              this.$Message.success(res.msg_customer)
              this.getAirRouteList(this.TeamID)
            } else {
              this.$Message.error(res.msg_customer)
            }
          })
        },
        onCancel: () => {},
      })
    },
    // 获取文件夹和任务列表数据
    getAirRouteList(id, task_name = '') {
      this.loading = true
      // 任务需后期修改，暂时取消-1获取全部
      if (id == -1 || id == '') {
        id = this.user_info.team_id
      }

      this.TeamID = id
      this.$post(Api.loadTaskList(), {
        client: 'web',
        dir_id: this.dirID,
        team_id: id,
        task_name: task_name,
      }).then((res) => {
        if (res.code == 1) {
          if (!res.data.dirList) {
            res.data.dirList = []
          }
          if (!res.data.tasklist) {
            res.data.tasklist = []
          } // 避免返回null后.length方法报错
          this.listData = []
          this.taskData = []
          if (res.data.dirList.length === 0 && res.data.tasklist.length === 0) {
            this.noData = true
            this.ShowMarker(res.data.tasklist, 3)
          } else {
            this.noData = false

            if (res.data.dirList !== []) {
              this.listData = res.data.dirList
            }
            // if (res.data.tasklist !== []) {
            //   res.data.tasklist.forEach((item) => {
            //     if (item.dir_id == 0) {
            //       this.taskData.push(item);
            //     }
            //   });
            // }
            // 在地图上显示项目地点
            this.ShowMarker(res.data.tasklist, 3)
            this.$store.state.ProjectListType = 2
          }
          this.loading = false

          // console.log("加载了任务信息");
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //进入文件夹
    IntoDir(team, id) {
      this.loading = true
      this.TeamID = team
      this.dirID = id
      sessionStorage.setItem(
        'dirInfo',
        JSON.stringify({ team: this.TeamID, id: this.dirID })
      )
      this.$post(Api.loadTaskList(), {
        task_name: this.searchMsg,
        client: 'web',
        team_id: team,
        dir_id: id,
      }).then((res) => {
        if (res.code == 1) {
          this.PageState = 1
          if (!res.data.tasklist) {
            res.data.tasklist = []
          }
          this.listData = []
          this.taskData = [] //  任务列表
          if (res.data.tasklist.length === 0) {
            if (this.searchMsg == '') {
              this.noData = true
              this.ShowMarker(res.data.tasklist, 3)
            } else {
              this.searchMsg = ''
              this.IntoDir(this.TeamID, this.dirID)
            }
          } else {
            this.noData = false

            res.data.tasklist.forEach((item) => {
              this.taskData.push(item)
            })

            // console.log("加载了任务信息");
            // 在地图上显示项目地点
            this.ShowMarker(res.data.tasklist, 3)
            this.$store.state.ProjectListType = 2
          }
          this.loading = false
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //返回文件夹和任务列表
    getBackDir() {
      sessionStorage.removeItem('dirInfo')
      this.dirID = -1
      this.searchMsg = ''
      this.getAirRouteList(this.selectedTeamData)
      this.PageState = 0
    },
    // 点击列表进入任务
    IntoheTask(item) {
      // console.log("点击列表进入任务",item);
      sessionStorage.setItem('getTaskData', JSON.stringify(item))
      sessionStorage.setItem('ProjectTaskinfo', JSON.stringify(item))
      this.$router.push({
        path: '/index/project/TaskBar',
      })
    },
    // 新建任务
    newTaskFn() {
      this.$router.push({
        path: '/route-specification',
        query: {
          team_id: this.TeamID ? this.TeamID : this.user_info.team_id,
          dir_id: this.dirID,
        },
      })
    },
    //添加文件夹
    addFolderFn() {
      if (this.addFolder_name == '') {
        this.$Message.warning('项目名称不能为空')
        return false
      }
      this.$post(Api.createTaskDir(), {
        client: 'web',
        team_id: this.TeamID ? this.TeamID : this.user_info.team_id,
        dir_name: this.addFolder_name,
      }).then((res) => {
        // console.log("团队数据",res);
        if (res.code == 1) {
          this.addFolderBox = false
          this.addFolder_name = ''
          this.getAirRouteList(this.TeamID)
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //关闭添加文件夹窗口
    closeAddFolder() {
      this.addFolder_name = ''
    },
    // 删除航线
    delete_Task(item) {
      this.$Modal.confirm({
        title: '删除任务',
        content: `是否删除  ${item.filetitle}  此任务,删除后数据无法恢复,谨慎删除!`,
        onOk: () => {
          if (item.tasktype == 'figure') {
            //建图
            this.$post(Api.buildFigureDel(), {
              bf_id: item.id,
            }).then((res) => {
              console.log('删除建图', res)
              if (res.code == 1) {
                this.getAirRouteList(this.TeamID)
              } else {
                this.$Message.error(res.msg_customer)
              }
            })
          } else {
            this.$post(Api.airRouteAllDel(), {
              client: 'web',
              air_route_id: item.id,
            }).then((res) => {
              console.log('删除航线', res)
              if (res.code == 1) {
                this.getAirRouteList(this.TeamID)
              } else {
                this.$Message.error(res.msg_customer)
              }
            })
          }
        },
        onCancel: () => {},
      })
    },
  },
  watch: {
    TeamID(val) {
      console.log(val)
    },
  },
}
</script>

<style scoped lang="scss">
#ProjectBar {
  position: relative;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
  // overflow: auto;
  .team-cascader {
    background: #000;
    width: 30px;
    height: 100%;
    cursor: pointer;
    > img {
      display: block;
      margin: 23px auto 0;
    }
  }
  .team-cascader:hover {
    background: #000;
  }
  .getBack {
    background-color: #fff;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    cursor: pointer;
    border-bottom: 1px solid #f3f3f3;
    i {
      font-size: 25px;
      color: #111;
      margin-right: 6px;
      line-height: 50px;
    }
    span {
      font-size: 20px;
      color: #111;
      line-height: 50px;
    }
  }
  .newBox {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-around;
    padding: 20px 10px 0;
    button {
      width: 150px;
      height: 46px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      cursor: pointer;
    }

    span {
      font-size: 18px;
      text-align: center;
      line-height: 46px;
    }
    .task {
      background: #2196f3;
      color: #fff;
    }
    .folder {
      background: #fff;
      color: #2196f3;
      border: 1px solid #2196f3;
    }
  }

  .searchProject {
    width: 320px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    input {
      width: 100%;
      height: 40px;
      font-size: 12px;
      color: #999;
      border: none;
      background-color: #fafafa;
      border-bottom: 1px solid #dcdee2;
      padding: 0 30px;
      text-align: center;
    }
    input:focus::-webkit-input-placeholder {
      color: transparent;
    }
    i {
      position: absolute;
      top: 10px;
      left: 0;
      font-size: 26px;
      color: #dcdee2;
    }
  }
  .ProjectList {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    z-index: 10;
    .noData {
      padding-top: 50px;
      h1 {
        width: 100%;
        text-align: center;
        color: #999999;
        font-size: 16px;
      }
    }
    > h1 {
      font-size: 12px;
      color: #c4c4c4;
      margin-bottom: 10px;
      margin-right: 20px;
      position: absolute;
      right: 10px;
      i {
        position: absolute;
        top: -3px;
        left: 21px;
        font-size: 25px;
        color: #c4c4c4;
      }
    }
    .ListBox1 {
      overflow-y: auto;
      height: calc(100vh - 300px);
      padding: 30px 0 50px;
    }
    .ListItem {
      width: 100%;
      height: 100px;
      padding: 0 10px 0 20px;
      background-color: #fff;
      border-width: 1px 1px 1px;
      border-style: solid;
      border-color: #fff #fff #f3f3f3;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 2px;
      position: relative;
      .ItemLeft {
        width: 80px;
        height: 100%;
        position: relative;
        // margin-right: 16px;
        img {
          // width: 37px;
          width: 25px;
          height: auto;
          @include center;
        }
      }
      .ItemContent {
        width: 210px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
          //   font-size: 16px;
          //   color: #111;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 16px;
          color: #333;
        }
        h3 {
          width: 100%;
          font-size: 12px;
          color: #999999;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .belongToTeam {
          .TeamColor {
            float: left;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-right: 4px;
            margin-top: 6px;
          }
        }
      }
      .ItemRight {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        .more_i {
          display: block;
          cursor: pointer;
          width: 30px;
          line-height: 34px;
          font-size: 30px;
          color: #ccc;
        }
        .api {
          margin: 4px 0;
          .Divider {
            width: 100%;
            height: 1px;
            margin: 6px 0;
            background-color: #c5c8ce;
          }
          .Button {
            cursor: pointer;
          }
        }
      }
    }
    .ListItem:hover {
      border: 1px solid #409eff;
    }
  }
  // .goTop {
  //   width: 80px;
  //   height: 30px;
  //   font-size: 12px;
  //   color: #fff;
  //   border-radius: 4px;
  //   background-color: #409eff;
  //   text-align: center;
  //   line-height: 30px;
  //   position: fixed;
  //   bottom: 20px;
  //   left: 80px;
  //   cursor: pointer;
  //   display: none;
  // }
}
#ProjectBar::-webkit-scrollbar {
  display: none;
}
</style>
